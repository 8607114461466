import { Attribute, ProjectController } from 'api';
import { getProjectIdFromUrl } from 'core';
import { makeAutoObservable } from 'mobx';

import { StatusEnum } from 'core/types';

export class AttributesStore {
  status: StatusEnum;

  attributesData: Attribute[];

  projectId: string;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.status = StatusEnum.idle;
    this.attributesData = [];
    this.projectId = '';
  }

  initializeSidebar(): void {
    this.projectId = getProjectIdFromUrl();
  }

  setStatus(value: StatusEnum): void {
    this.status = value;
  }

  setAttributesData(value: Attribute[]): void {
    this.attributesData = value;
  }

  async fetchAttributesData(key: string): Promise<void> {
    try {
      this.setStatus(StatusEnum.loading);

      const { data } = await ProjectController.apiProjectIdAttributesKeyGet(
        this.projectId,
        key
      );

      this.setAttributesData(data);
      this.setStatus(StatusEnum.idle);
    } catch (error) {
      this.setStatus(StatusEnum.failed);
    }
  }
}

export const AttributesStoreInstance = new AttributesStore();
export type AttributesStoreType = typeof AttributesStoreInstance;
