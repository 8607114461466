import React, { useMemo } from 'react';

import { AttributesStoreInstance } from 'features/attributes';
import { ContentStoreInstance } from 'features/content';
import { CoreStoreInstance } from 'core';
import { TreeStoreInstance } from 'features/tree';

import { StoreState } from '../types';

type StoreProviderProps = {
  children?: React.ReactNode;
};

export const StoreProvider: React.FC = ({ children }: StoreProviderProps) => {
  const core = CoreStoreInstance;
  const attributes = AttributesStoreInstance;
  const tree = TreeStoreInstance;
  const content = ContentStoreInstance;

  const store = useMemo(() => ({ core, attributes, tree, content }), [
    core,
    attributes,
    tree,
    content,
  ]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const storeContext = React.createContext<StoreState | null>(null);

export default StoreProvider;
