import { StorageAdapter, persistence } from 'mobx-persist-store';
import { makeAutoObservable } from 'mobx';
import { readStoreLS, writeStoreLS } from 'libs';

import { ProjectController } from 'api';
import { StatusEnum } from '../types';

export class CoreStore {
  status: StatusEnum;

  projectId: string;

  projectName: string;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.status = StatusEnum.idle;
    this.projectId =
      new URLSearchParams(window.location.search).get('projectId') || '';
    this.projectName = '';
    this.fetchProjectData(this.projectId);
  }

  setStatus(value: StatusEnum): void {
    this.status = value;
  }

  setProjectName(value: string): void {
    this.projectName = value;
  }

  async fetchProjectData(projectId: string): Promise<void> {
    try {
      this.setStatus(StatusEnum.loading);

      const { data } = await ProjectController.apiProjectIdGet(projectId);

      this.setProjectName(data.name || '');

      this.setStatus(StatusEnum.idle);
    } catch (error) {
      this.setStatus(StatusEnum.failed);
    }
  }
}

export const CoreStoreInstance = persistence({
  name: `persist:${process.env.REACT_APP_NAME}:v${process.env.REACT_APP_VERSION}:core`,
  properties: ['status'],
  adapter: new StorageAdapter({
    read: readStoreLS,
    write: writeStoreLS,
  }),
  reactionOptions: {
    delay: 300,
  },
})(new CoreStore());
export type CoreStoreType = typeof CoreStoreInstance;
