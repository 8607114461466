import { Result } from 'antd';
import React from 'react';

import css from './index.module.css';

type SpinnerProps = {
  title: string;
};
const Stager: React.FC<SpinnerProps> = ({ title }: SpinnerProps) => (
  <Result
    className={css.stager}
    icon={
      <div className={css.scaler}>
        <div className={css.spinner}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    }
    title={title}
  />
);

export default Stager;
