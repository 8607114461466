import { Layout } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import cn from 'classnames';

import { AttributesWrapper } from 'features/attributes';
import { Content } from 'features/content';
import { Header } from 'features/header';
import { TreeWrapper } from 'features/tree';

import css from './index.module.css';

const Core: FC = observer(() => {
  return (
    <Layout className={css.layout}>
      <Layout.Header className={css.header}>
        <Header />
      </Layout.Header>
      <Layout>
        <Layout.Sider className={cn(css.sider, css.siderLeft)} theme="light">
          <TreeWrapper />
        </Layout.Sider>
        <Layout.Content className={css.content}>
          <Content />
        </Layout.Content>
        <Layout.Sider className={cn(css.sider, css.siderRight)} theme="light">
          <AttributesWrapper />
        </Layout.Sider>
      </Layout>
    </Layout>
  );
});

export default Core;
