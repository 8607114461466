import { Tree } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, Key, useEffect } from 'react';

import { useRootData } from 'hooks';
import css from './index.module.css';

const TreeWrapper: FC = observer(() => {
  const {
    treeData,
    selectedKeys,
    expandedKeys,
    selectedObject,
    initializeSidebar,
    setSelectedKeys,
    setExpandedKeys,
    selectObject,
  } = useRootData((state) => ({
    treeData: state.tree.treeData,
    selectedKeys: state.tree.selectedKeys,
    expandedKeys: state.tree.expandedKeys,
    selectedObject: state.content.selectedObject,
    initializeSidebar: state.tree.initializeSidebar,
    setSelectedKeys: state.tree.setSelectedKeys,
    setExpandedKeys: state.tree.setExpandedKeys,
    selectObject: state.content.selectObject,
  }));

  useEffect(() => initializeSidebar(), [initializeSidebar]);

  useEffect(() => {
    const key = selectedObject?.userData.key;
    setSelectedKeys(key ? [key] : []);
  }, [selectedObject, setSelectedKeys]);

  const handleExpand = (keys: Key[]) => setExpandedKeys(keys);

  const handleSelect = (keys: Key[]) => {
    const [singlekey] = keys;
    selectObject(singlekey);
  };

  return (
    <div className={css.wrapper}>
      <Tree
        expandedKeys={expandedKeys}
        onExpand={handleExpand}
        onSelect={handleSelect}
        selectedKeys={selectedKeys}
        treeData={treeData}
      />
    </div>
  );
});

export default TreeWrapper;
