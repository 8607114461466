import { Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { StatusEnum } from 'core/types';
import { useRootData } from 'hooks';
import css from './index.module.css';

const { Link } = Typography;

const AttributesList: FC = observer(() => {
  const { attributesData, status } = useRootData((state) => ({
    attributesData: state.attributes.attributesData,
    status: state.attributes.status,
  }));

  return attributesData.length > 0 && status === StatusEnum.idle ? (
    <div>
      {attributesData.map((attr) => (
        <div key={attr.id} className={css.attribute}>
          <div className={css.attributeTitle}>{attr.title}:</div>
          <div className={css.attributeValue}>
            {attr.type === 'link' ? (
              <Link href={attr.value!} target="_blank">
                ссылка
              </Link>
            ) : (
              attr.value
            )}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div>Нет данных</div>
  );
});

export default AttributesList;
