import { observer } from 'mobx-react';
import { useRootData } from 'hooks';
import React, { FC, useEffect } from 'react';

import { AttributesList } from '../components';

import css from './index.module.css';

const AttributesWrapper: FC = observer(() => {
  const {
    selectedKeys,
    initializeSidebar,
    setAttributesData,
    fetchAttributesData,
  } = useRootData((state) => ({
    selectedKeys: state.tree.selectedKeys,
    initializeSidebar: state.attributes.initializeSidebar,
    setAttributesData: state.attributes.setAttributesData,
    fetchAttributesData: state.attributes.fetchAttributesData,
  }));

  useEffect(() => initializeSidebar(), [initializeSidebar]);

  useEffect(() => {
    const [singleKey] = selectedKeys;
    if (singleKey) {
      fetchAttributesData(singleKey as string);
    } else {
      setAttributesData([]);
    }
  }, [selectedKeys, fetchAttributesData, setAttributesData]);

  return (
    <div className={css.wrapper}>
      {selectedKeys.length ? (
        <AttributesList />
      ) : (
        <div>Выберите объекты в дереве или в модели</div>
      )}
    </div>
  );
});

export default AttributesWrapper;
