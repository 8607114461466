import { observer } from 'mobx-react';
import { useRootData } from 'hooks';
import React, { FC, useEffect } from 'react';

import { ModelLoaderSpinner } from '../components';
import css from './index.module.css';

const Content: FC = observer(() => {
  const { initialize } = useRootData((state) => ({
    initialize: state.content.initialize,
  }));

  useEffect(() => initialize(), [initialize]);

  return (
    <div className={css.wrapper} id="canvas-wrapper">
      <ModelLoaderSpinner />
      <canvas className={css.canvas} id="canvas" />
    </div>
  );
});

export default Content;
