import { observer } from 'mobx-react';
import { useRootData } from 'hooks';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';

import { Stager } from 'core/components';
import { StatusEnum } from 'core/types';

const ModelLoadingSpinner: FC = observer(() => {
  const { t } = useTranslation();
  const { modelLoadingStatus, modelLoadingProgress } = useRootData((state) => ({
    modelLoadingStatus: state.content.modelLoadingStatus,
    modelLoadingProgress: state.content.modelLoadingProgress,
  }));

  return modelLoadingStatus === StatusEnum.loading ? (
    <Stager title={`${t('loadingModel')}: ${modelLoadingProgress}%`} />
  ) : null;
});

export default ModelLoadingSpinner;
