/* eslint-disable no-console */
import {
  Color,
  HemisphereLight,
  Mesh,
  MeshStandardMaterial,
  Object3D,
  PerspectiveCamera,
  Raycaster,
  Scene,
  Vector2,
  WebGLRenderer,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Key } from 'react';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { makeAutoObservable } from 'mobx';

import { StatusEnum } from 'core/types';
import { getProjectIdFromUrl } from 'core';

export class ContentStore {
  canvasElement: HTMLCanvasElement | null;

  renderer: WebGLRenderer | null;

  scene: Scene | null;

  camera: PerspectiveCamera | null;

  raycaster: Raycaster | null;

  cursorPointer: Vector2;

  orbitControls: OrbitControls | null;

  modelLoadingStatus: StatusEnum;

  modelLoadingProgress: number;

  modelObject3d: Object3D | null;

  selectedObject: Mesh | null;

  selectedObjectEmissive: number;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.canvasElement = null;
    this.renderer = null;
    this.scene = null;
    this.camera = null;
    this.raycaster = null;
    this.cursorPointer = new Vector2();
    this.orbitControls = null;
    this.modelLoadingStatus = StatusEnum.idle;
    this.modelLoadingProgress = 0;
    this.modelObject3d = null;
    this.selectedObject = null;
    this.selectedObjectEmissive = 0x000000;
  }

  initialize(): void {
    const projectId = getProjectIdFromUrl();
    projectId && this.initializeScene3d(projectId);
  }

  setModelLoadingProgress(value: number): void {
    this.modelLoadingProgress = value;
  }

  initializeScene3d(projectId: string): void {
    this.canvasElement = document.getElementById('canvas') as HTMLCanvasElement;

    this.renderer = new WebGLRenderer({
      canvas: this.canvasElement,
      antialias: true,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);

    const width = this.canvasElement?.clientWidth;
    const height = this.canvasElement?.clientHeight;
    this.renderer?.setSize(width, height, false);

    this.scene = new Scene();
    this.scene.background = new Color('lightgray');

    const aspect = width / height;

    this.camera = new PerspectiveCamera(50, aspect, 0.1, 10000);
    this.camera.position.set(0, 10, 20);

    this.orbitControls = new OrbitControls(this.camera, this.canvasElement);
    this.orbitControls.target.set(0, 5, 0);
    this.orbitControls.update();

    const light = new HemisphereLight(0xb1e1ff, 0xb97a20, 1);
    this.scene.add(light);

    const loader = new GLTFLoader();

    this.modelLoadingStatus = StatusEnum.loading;
    loader.load(
      process.env.NODE_ENV === 'development'
        ? '/models/CoalMillDeptlight.gltf'
        : `/api/project/${projectId}/model3d`,
      (gltf) => {
        this.scene?.add(gltf.scene);

        this.modelObject3d = gltf.scene;
        this.modelLoadingStatus = StatusEnum.idle;
      },
      (xhr) =>
        this.setModelLoadingProgress(
          Math.floor((xhr.loaded / xhr.total) * 100)
        ),
      (error) => {
        console.log(error);
        this.modelLoadingStatus = StatusEnum.failed;
      }
    );

    this.raycaster = new Raycaster();
    this.canvasElement.addEventListener('click', this.onClick);

    this.animate();
  }

  animate(): void {
    requestAnimationFrame(this.animate);
    this.updateSize();

    if (
      this.scene &&
      this.camera &&
      this.renderer &&
      this.modelLoadingStatus !== StatusEnum.loading
    ) {
      this.renderer.render(this.scene, this.camera);
    }
  }

  updateSize(): void {
    const width = this.canvasElement?.clientWidth;
    const height = this.canvasElement?.clientHeight;

    if (width && height) {
      if (
        this.camera &&
        (this.canvasElement?.width !== width ||
          this.canvasElement?.height !== height)
      ) {
        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();
        this.renderer?.setSize(width, height, false);
      }
    }
  }

  onClick(event: MouseEvent): void {
    event.preventDefault();

    if (
      this.canvasElement &&
      this.scene &&
      this.camera &&
      this.raycaster &&
      this.modelObject3d
    ) {
      this.cursorPointer.x =
        (event.offsetX / this.canvasElement.clientWidth) * 2 - 1;
      this.cursorPointer.y =
        -(event.offsetY / this.canvasElement.clientHeight) * 2 + 1;

      this.raycaster.setFromCamera(this.cursorPointer, this.camera);

      const intersects = this.raycaster.intersectObjects(
        this.modelObject3d.children
      );

      // Сброс выделения последнего выбранного объекта
      if (this.selectedObject) {
        (this.selectedObject?.material as MeshStandardMaterial).emissive.setHex(
          this.selectedObjectEmissive
        );
        this.selectedObject = null;
      }

      // При клике на объекты => выделение ближайшего объекта
      if (intersects.length > 0) {
        const closestObject = intersects[0].object as Mesh;

        this.selectedObject = closestObject;
        this.selectedObjectEmissive = (closestObject.material as MeshStandardMaterial).emissive.getHex();
        (this.selectedObject.material as MeshStandardMaterial).emissive.setHex(
          0xff0000
        );
      }
    }
  }

  selectObject(key: Key): void {
    const object = this.modelObject3d?.children.find(
      (child) => child.userData.key === key
    ) as Mesh;

    // Небольшая проблема с типизацией, скорее всего что-то не так с gltf.
    if (object) {
      if (object.name !== this.selectedObject?.name) {
        if (this.selectedObject) {
          (this.selectedObject
            .material as MeshStandardMaterial).emissive.setHex(
            this.selectedObjectEmissive
          );
        }
        this.selectedObject = object;
        this.selectedObjectEmissive = (object.material as MeshStandardMaterial).emissive.getHex();
        (this.selectedObject.material as MeshStandardMaterial).emissive.setHex(
          0xff0000
        );
      }
    } else {
      if (this.selectedObject) {
        (this.selectedObject?.material as MeshStandardMaterial).emissive.setHex(
          this.selectedObjectEmissive
        );
      }
      this.selectedObject = null;
    }
  }
}

export const ContentStoreInstance = new ContentStore();
export type ContentStoreType = typeof ContentStoreInstance;
