import { observer } from 'mobx-react';
import { useRootData } from 'hooks';
import React, { FC } from 'react';

import css from './index.module.css';

const Header: FC = observer(() => {
  const { projectName } = useRootData((state) => ({
    projectName: state.core.projectName,
  }));

  return (
    <div className={css.wrapper}>
      <h2 className={css.projectTitle}>{projectName}</h2>
    </div>
  );
});

export default Header;
