import { Node } from '../../../api';

const convertFlatToTree = (structure: Node[], root: string | null): any => {
  return structure.reduce((currentLevelNodes: Node[], node: Node) => {
    const { key, title, parent } = node;
    if (parent === root) {
      if (structure.some((s) => s.parent === key)) {
        return [
          ...currentLevelNodes,
          {
            key,
            title,
            children: convertFlatToTree(structure, key || ''),
          },
        ];
      }

      return [
        ...currentLevelNodes,
        {
          title,
          key,
        },
      ];
    }

    return currentLevelNodes;
  }, []);
};

export default convertFlatToTree;
