import { Key } from 'react';
import { StatusEnum } from 'core/types';
import { getProjectIdFromUrl } from 'core';
import { makeAutoObservable } from 'mobx';

import { Node, ProjectController } from '../../../api';
import { TreeNode } from '../types';
import { convertFlatToTree } from '../utils';

export class TreeStore {
  status: StatusEnum;

  treeData: TreeNode[];

  treeDataFlat: Node[];

  expandedKeys: Key[];

  selectedKeys: Key[];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    this.status = StatusEnum.idle;
    this.treeData = [];
    this.treeDataFlat = [];
    this.expandedKeys = [];
    this.selectedKeys = [];
  }

  initializeSidebar(): void {
    const projectId = getProjectIdFromUrl();
    projectId && this.fetchTreeDataFlat(projectId);
  }

  setStatus(value: StatusEnum): void {
    this.status = value;
  }

  setExpandedKeys(value: Key[]): void {
    this.expandedKeys = value;
  }

  setSelectedKeys(value: Key[]): void {
    this.selectedKeys = value;
  }

  setTreeData(value: Node[]): void {
    this.treeDataFlat = value;
  }

  setTreeDataFlat(value: Node[]): void {
    this.setTreeData(value);
    this.setTreeData(convertFlatToTree(value, ''));
  }

  async fetchTreeDataFlat(projectId: string): Promise<void> {
    try {
      this.setStatus(StatusEnum.loading);

      const { data } = await ProjectController.apiProjectIdTreeTypeGet(
        projectId,
        'model'
      );

      this.treeDataFlat = data;
      this.treeData = convertFlatToTree(data, null);
      this.setStatus(StatusEnum.idle);
    } catch (error) {
      this.setStatus(StatusEnum.failed);
    }
  }
}

export const TreeStoreInstance = new TreeStore();
export type TreeStoreType = typeof TreeStoreInstance;
